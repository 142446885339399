import React, { useContext } from 'react';
import Link from 'next/link';
import { formatValue } from '../../../utils/currency';
import StoreContext from '../../../utils/store/StoreContext';
import { useTranslation } from 'react-i18next';

const MasterProductDetail = ({ product, uniqueTags, des }) => {
  const { t } = useTranslation('common');
  const { store } = useContext(StoreContext);
  let RatingStars = [];
  let rating = 5;
  for (var i = 0; i < rating; i++) {
    RatingStars.push(<i className="fa fa-star" key={i}></i>);
  }

  const productOff =
    !product.enabled ||
    (product.stockControl &&
      product.currentStock <= 0 &&
      store.outStockProduct) == 'u';

  return (
    <Link href={`/product/${product.slug}`}>
      <a>
        <div className={`product-detail mt-2`}>
          <div>
            <h5>{product.name}</h5>
            {productOff ? (
              <h4>{t('Unavailable')}</h4>
            ) : (
              <>
                <h4>
                  {product.promoPrice
                    ? formatValue(product.promoPrice, store.currency)
                    : formatValue(product.salePrice, store.currency)}
                  {product.promoPrice ? (
                    <del>
                      <span className="money">
                        {formatValue(product.salePrice, store.currency)}
                      </span>
                    </del>
                  ) : null}
                </h4>
              </>
            )}

            {product.variants &&
              product.variants.map((vari) => {
                var findItem = uniqueTags.find((x) => x.color === vari.color);
                if (!findItem) uniqueTags.push(vari);
              })}
            {des ? <div className="description" dangerouslySetInnerHTML={{ __html: product.description }} /> : ''}
          </div>
        </div>
      </a>
    </Link>
  );
};

export default MasterProductDetail;
